const isExperimental = import.meta.env.VITE_ENVIRONMENT === 'experimental';
// prettier-ignore
export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => isExperimental ?
            import('@/landing/flows/ExperimentalLandingPage/HomeContainer.vue') :
            import('@/landing/flows/LandingPage/HomeContainer.vue'),
        meta: {
            authenticate: 'home', //only one not boolean authenticate, cuz home page specially managed by route.name
        },
    },
    {
        path: '/flags',
        component: () => import('./core/pages/Doc/DocFlags.vue'),
        meta: {
            authenticate: true,
        },
        beforeEnter: (to, from, next) => {
            let isDev = localStorage.getItem('dev-tools');

            if (!isDev) {
                return next({ name: 'home' })
            }
            next();
        },
    },
    {
        path: '/login',
        name: 'auth.login',
        props: true,
        component: () =>
            import('@/flows/Authentication/LoginView/LoginView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/login/cert',
        name: 'auth.loginCert',
        props: true,
        component: () =>
            import('@/flows/TimeBasedLiveGame/Host/GameEndedView/components/Podium/LoginViewCertificateWrapper.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/login/partner/:partner',
        name: 'auth.login.partner',
        props: true,
        component: () =>
            import('@/flows/Authentication/LoginView/LoginView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/register',
        name: 'auth.register',
        props: true,
        component: () =>
            import('@/flows/Authentication/RegisterView/RegisterView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/forgot',
        name: 'auth.forgot',
        props: true,
        component: () =>
            import('@/flows/Authentication/ForgotPasswordView/ForgotPasswordView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/change-password',
        name: 'auth.change-password',
        props: true,
        component: () =>
            import('@/flows/Authentication/ChangePasswordView/ChangePasswordView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/coronavirus-remote-learning',
        name: 'remote-learning',
        component: () => import('./landing/flows/RemoteLearning/RemoteLearningContainer.vue'),
    },
    {
        path: '/eriolukord-oppetoos-kaug-ja-koduope/(\\?ref_id{=:upviralId})?',
        name: 'remote-learning-estonia',
        component: () => import('./landing/flows/RemoteLearning/RemoteLearningContainer.vue'),
        props: true,
    },
    {
        path: '/eriolukord-oppetoos-kaug-ja-koduope',
        name: 'remote-learning-estonia',
        component: () => import('./landing/flows/RemoteLearning/RemoteLearningContainer.vue'),
        props: true,
    },
    {
        path: '/join',
        name: 'join',
        component: () => import('@/student/flows/JoinGame/JoinGameContainer.vue'),
        children: [
            {
                path: ':gameCode',
                name: 'join.gamecode',
                component: () => import('@/student/flows/JoinGame/JoinGameContainer.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/math-madness',
        name: 'math-madness',
        component: () =>
            import('@/flows/MathMadness/MathMadnessCompetitionPage.vue'),
    },
    {
        path: '/math-madness-leaderboard/:participantId',
        name: 'math-madness-leaderboard',
        component: () =>
            import('@/flows/MathMadness/MadnessLeaderboardPage.vue'),
        props: true,
    },
    {
        path: '/buy-premium/:userId',
        name: 'buy-premium',
        component: () =>
            import('@/flows/PaymentModal2/ExternalPaymentView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/premium-buy',
        name: 'parent.buy-premium',
        component: () =>
            import('@/student/flows/ParentPaymentModal/ParentPaymentView.vue'),
    },
    {
        path: '/live-game',
        name: 'live-game',
        component: () =>
import('@/flows/TimeBasedLiveGame/Player/LiveGame/LiveGameContainer.vue'),
        children: [
            {
                path: 'preview/:code',
                name: 'livegame.preview',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Player/LiveGame/PreGameView/PreGameView.vue'),
                props: true,
            },
            {
                path: 'results/:code',
                name: 'livegame.results',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Player/LiveGame/GameEndedView.vue'),
                props: true,
            },
            {
                path: 'play/:code',
                name: 'livegame.gameview',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Player/LiveGame/GameView/GameView.vue'),
                props: true,
            },
            {
                path: 'warmup/:code',
                name: 'livegame.warmup',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Player/LiveGame/WarmUpView/WarmUpView.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/self-paced-game',
        name: 'self-paced-game',
        component: () =>
            import('@/flows/SelfPacedGame/SPGContainer.vue'),
        children: [
            {
                path: 'preview/:code',
                name: 'self-paced-playlist.preview',
                component: () =>
                    import('@/flows/SelfPacedGame/PreGameView/PreGamePlaylistView.vue'),
                props: true,
            },
            {
                path: 'preview/:code',
                name: 'self-paced-game.preview',
                component: () =>
                    import('@/flows/SelfPacedGame/PreGameView/PreGameView.vue'),
                props: true,
            },
            {
                path: 'results/:code',
                name: 'self-paced-game.results',
                component: () =>
                    import('@/flows/SelfPacedGame/GameEndedView.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/player',
        name: 'player',
        component: () =>
            import('@/flows/SelfPacedGame/PlayerContainer.vue'),
        children: [
            {
                path: ':gameCode/self-paced/play',
                name: 'player.self-paced-play',
                component: () => import('@/flows/SelfPacedGame/SelfPacedGameView/SelfPacedGameView.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/profile',
        component: () =>
            import('@/flows/User/UserContainer.vue'),
        meta: {
            authenticate: true,
        },
        children: [
            {
                path: '',
                name: 'user-profile',
                component: () =>
                    import('@/flows/User/ProfileView/UserProfileView.vue'),
                props: true,
            },
            {
                path: 'progress',
                name: 'user-profile.progress',
                component: () =>
                    import('@/flows/User/ProgressView/ProgressView.vue'),
                props: true,
            },
            {
                path: '/profile/teachers-avatar',
                name: 'user-profile.teachers-avatar',
                component: () =>
import('@/flows/User/ProfileView/components/SetTeachersAvatar/components/TeachersAvatarsPage.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/profile/public/:userId/:userPlayerName',
        name: 'user-profile.public.with-player-name',
        component: () =>
            import('@/student/pages/PublicProfileView/PublicProfileView.vue'),
        props: true,
    },
    {
        path: '/profile/public/:userId',
        name: 'user-profile.public',
        component: () =>
            import('@/student/pages/PublicProfileView/PublicProfileView.vue'),
        props: true,
    },
    {
        path: '/invite/:userId',
        name: 'user-invite-link',
        component: () =>
            import('@/flows/ReferralProgram/UserInviteLink.vue'),
        props: true,
        meta: {
            authenticate: false,
        },
    },
    {
        /**
         * Set flags to current logged-in user.
         */
        path: '/flags/unlock/:flag',
        component: () =>
            import('@/core/helpers/FlagUnlocker.vue'),
        props: true,
        meta: {
            authenticate: true,
        },
    },
    {
        /**
         * Set flags for client who hopefully signs up and then these flags
         * will be saved to the user
         */
        path: '/test/:flag',
        component: () =>
            import('@/core/helpers/FlagUnlocker.vue'),
        props: true,
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/:ID',
        name: 'partner-tracking',
        component: () =>
            import('@/flows/ReferralProgram/ReferralView.vue'),
        props: true,
    },
    {
        path: '/enable/clever/flag',
        name: 'clever-flag',
        component: () =>
            import('@/core/helpers/CleverUnlocker.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/tooling/live/admin',
        name: 'clever-flag',
        component: () =>
            import('@/core/helpers/LiveGameTooling.vue'),
        meta: {
            authenticate: true,
        },
    },
    {
        path: '/games-list',
        name: 'games-list',
        component: () =>
            import('@/flows/MathRunner/GameList/GameList.vue'),
    },
    {
        path: '/create-game',
        name: 'runner.create-game',
        component: () =>
            import('@/flows/MathRunner/Revamp/GameCreation.vue'),
    },
    {
        path: '/online-players',
        name: 'online-players',
        component: () =>
            import('@/flows/MathRunner/OnlinePlayersList/OnlinePlayersList.vue'),
    },
    {
        path: '/gomobile',
        name: 'gomobile',
        component: () => import('@/student/widgets/MobileAppPromo/MobileAppRedirect.vue'),
    },
    {
        path: '/friends',
        name: 'friends',
        component: () =>
            import('@/student/pages/FriendsPage.vue'),
    },
    {
        path: '/public-monthly-competition',
        name: 'public-monthly-competition',
        component: () => import('@/teacher/pages/MonthlyCompetition/MonthlyCompetitionPublicLandingPage.vue')
    }
];
