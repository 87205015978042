import { capitalize } from '@/core/helpers/utils.js';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService.js';
import SecureApi from '@/flows/Authentication/services/SecureApi.js';
import { LEAGUES_CONFIG } from '@/core/static-json/competitionLeaguesConfig.js';
import { COMPETITION_CONSTANTS } from '@/core/static-json/competitionsConfig.js';
import _ from 'lodash';
import moment from 'moment';
import { getStateShorthand } from '@/core/helpers/utils';

function reportError(message, error) {
    console.error(message, error);
}

function formatScore(score) {
    return !score || score === 0 ? 0 : +score.toFixed(2);
}

function formatDateRange(startAt, endAt) {
    const startMonth = moment(startAt).format('MMMM');
    const startDay = moment(startAt).format('D');
    const endMonth = moment(endAt).format('MMMM');
    const endDay = moment(endAt).format('D');

    if (startMonth === endMonth) {
        return `${startMonth} ${startDay} - ${endDay}`;
    } else {
        return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
    }
}

function getAndFormatWinnersDates(eventEndAt) {
    const winnersStart = moment(eventEndAt).add(1, 'day');
    const winnersEnd = moment(winnersStart).add(8, 'days');
    return formatDateRange(winnersStart, winnersEnd);
}

export function getGradeEnding(grade) {
    switch (grade.toString()) {
        case '1':
            return 'st';
        case '2':
            return 'nd';
        case '3':
            return 'rd';
        case 'K':
            return '';
        default:
            return 'th';
    }
}

export default {
    namespaced: true,
    state: {
        competitionsConfig: [],
        overviewTopStateSchools: [],
        overviewMyClasses: [],
        overviewTopClasses: [],
        overviewRisingClasses: [],
        selectedYearlyLeague: LEAGUES_CONFIG[0],
        seasonTotalParticipants: 0,
        teams: [],
        leaderboard: [],
        infiniteLeaderboard: [],
        competitionClassCode: undefined,
        competitionTeam: null,
        customGrade: null,
        selectedCompetition: null,
        leaderboardTopStudents: [],
        leaderboardMostImproved: [],
        leaderboardTopHomePlayers: [],
        leaderboardUpdateTime: undefined,
        ownRecords: [],
        teamsIsLoaded: false,
    },
    getters: {
        competitionsConfig: (state) => {
            const now = moment();
            return state.competitionsConfig
                .map((competition) => {
                    let status;
                    if (
                        now.isBetween(
                            moment.utc(competition.startAt),
                            moment.utc(competition.endAt),
                        )
                    ) {
                        status = COMPETITION_CONSTANTS.LIVE;
                    }
                    if (now.isAfter(moment.utc(competition.endAt))) {
                        status = COMPETITION_CONSTANTS.ENDED;
                    }
                    if (now.isBefore(moment.utc(competition.startAt))) {
                        status = COMPETITION_CONSTANTS.NEW;
                    }
                    return {
                        ...competition,
                        status,
                    };
                })
                .filter((competition) => competition.id !== 'freeTrial');
        },
        currentCompetitionEvent: (state, getters) => {
            const fakeNow = sessionStorage.getItem('fakeNow');
            const now = fakeNow ? moment(fakeNow, 'DD.MM.YYYY') : moment();
            const config = getters.competitionsConfig;
            const inProgressEvent = config.find(
                (competition) =>
                    moment(competition.startAt).isBefore(now) &&
                    moment(competition.endAt).isAfter(now),
            );
            if (inProgressEvent) {
                return {
                    status: 'Current',
                    label: 'Current event:',
                    dates: formatDateRange(
                        inProgressEvent.startAt,
                        inProgressEvent.endAt,
                    ),
                    winners: getAndFormatWinnersDates(inProgressEvent.endAt),
                    event: inProgressEvent,
                };
            }
            const nextEvent = config.find((competition) =>
                moment(competition.startAt).isAfter(now),
            );
            if (nextEvent) {
                const nextEventIndex = config.findIndex(
                    (item) => item.id === nextEvent.id,
                );

                const prevConfig = config[nextEventIndex - 1];
                const prevEvent = prevConfig
                    ? {
                          status: 'Past',
                          label: 'Past event:',
                          event: prevConfig,
                          dates: formatDateRange(
                              prevConfig.startAt,
                              prevConfig.endAt,
                          ),
                          winners: getAndFormatWinnersDates(prevConfig.endAt),
                      }
                    : undefined;

                return {
                    status: 'Next',
                    label: 'Next event:',
                    dates: formatDateRange(nextEvent.startAt, nextEvent.endAt),
                    winners: getAndFormatWinnersDates(nextEvent.endAt),
                    event: nextEvent,
                    prevEvent,
                };
            }
            return {
                status: 'Ended',
                label: 'Season has ended',
                dates: '',
                prevEvent: config[config.length - 1],
            };
        },
        currentCompetitionBanner: (state, getters) => {
            const fakeNow = sessionStorage.getItem('fakeNow');
            const now = fakeNow ? moment(fakeNow, 'DD.MM.YYYY') : moment();
            const config = getters.competitionsConfig;
            const inProgressEvent = config.find(
                (competition) =>
                    moment(competition.startAt).isBefore(now) &&
                    moment(competition.endAt).isAfter(now),
            );
            if (inProgressEvent) {
                return {
                    status: 'Current',
                    event: inProgressEvent,
                };
            }
            const previousEvent = config.find((competition) => {
                const endDate = moment(competition.endAt);
                const twoWorkingDaysAfterEnd = moment(
                    competition.endAt,
                ).clone();
                // Add working days (skip weekends)
                let daysAdded = 0;
                while (daysAdded < 2) {
                    twoWorkingDaysAfterEnd.add(1, 'days');
                    // Skip weekends (0 is Sunday, 6 is Saturday in moment.js)
                    if (
                        twoWorkingDaysAfterEnd.day() !== 0 &&
                        twoWorkingDaysAfterEnd.day() !== 6
                    ) {
                        daysAdded++;
                    }
                }
                twoWorkingDaysAfterEnd.endOf('day'); // Round up to end of day (23:59:59)
                return (
                    endDate.isBefore(now) &&
                    now.isBefore(twoWorkingDaysAfterEnd)
                );
            });
            if (previousEvent) {
                return {
                    status: 'Ended',
                    event: previousEvent,
                };
            }
            const nextEvent = config.find((competition) =>
                moment(competition.startAt).isAfter(now),
            );
            if (nextEvent) {
                return {
                    status: 'Next',
                    label: 'Next event:',
                    dates: formatDateRange(nextEvent.startAt, nextEvent.endAt),
                    winners: getAndFormatWinnersDates(nextEvent.endAt),
                    event: nextEvent,
                };
            }
            return null;
        },
        teams: (state) => state.teams,
        getCompetitionGrade: (state) => {
            return state.customGrade || state.competitionTeam?.grade;
        },
        getCompetitionGradeLabel: (state, getters) => {
            const grade = getters.getCompetitionGrade;
            if (grade) {
                const ending = getGradeEnding(grade);
                return `${grade}${ending} Grade`;
            } else {
                return '';
            }
        },
        /**
         * @deprecated state.leaderboard is not a main leaderboard
         */
        leaderboardFilteredCount: (state) => {
            return state.leaderboard[0]?.filteredParticipants || 0;
        },
        /**
         * @deprecated state.leaderboard is not a main leaderboard
         */
        leaderboardTotalCount: (state) => {
            return state.leaderboard[0]?.totalParticipants || 0;
        },
        leaderboardUpdateText: (state) => () => {
            const time = moment
                .duration(moment().diff(state.leaderboardUpdateTime))
                .humanize();
            return `Last updated ${time} ago`;
        },
        leaguePositionRanges: (state) => {
            const totalParticipants = state.seasonTotalParticipants;
            let positionRanges = {};

            LEAGUES_CONFIG.forEach((league) => {
                const fromPos = Math.floor(
                    totalParticipants * ((100 - league.to) / 100),
                );
                positionRanges[league.id] = {
                    from: fromPos === 0 ? 0 : fromPos + 1,
                    to: Math.floor(
                        totalParticipants * ((100 - league.from) / 100),
                    ),
                };
            });

            return positionRanges;
        },
        currentLeagueRange: (state, getters) => {
            return getters.leaguePositionRanges[state.selectedYearlyLeague.id];
        },
    },
    mutations: {
        setCompetitionsConfig: (state, config) => {
            state.competitionsConfig = config;
            state.selectedCompetition = config[0];
        },
        addOrUpdateTeam: (state, team) => {
            const pos = state.teams.findIndex(
                (t) => t.classCode === team.classCode,
            );
            if (pos > -1) state.teams[pos] = team;
            else state.teams.push(team);
        },
        setSelectedYearlyLeague: (state, payload) => {
            state.selectedYearlyLeague = payload;
        },
        setTeams: (state, teams) => {
            console.debug(
                'setTeams mutation',
                teams,
                state.competitionClassCode,
            );
            state.teams = teams;
            if (state.competitionClassCode) {
                state.competitionTeam = state.teams.find(
                    (team) => team.classCode === state.competitionClassCode,
                );
            }
        },
        setSeasonTotalParticipants: (state, totalParticipants) => {
            state.seasonTotalParticipants = totalParticipants;
        },
        setLeaderboard: (state, leaderboard) => {
            state.leaderboard = leaderboard.map((item) => {
                return {
                    ...item,
                    schoolScore: formatScore(item.schoolScore),
                    homeScore: formatScore(item.homeScore),
                    totalScore: formatScore(item.totalScore),
                    seasonScore: formatScore(item.seasonScore),
                };
            });
        },
        setOverviewMyClasses: (state, leaderboard) => {
            state.overviewMyClasses = leaderboard.map((item) => {
                return {
                    ...item,
                    totalScore: formatScore(item.totalScore),
                };
            });
        },
        setOverviewTopClasses: (state, leaderboard) => {
            state.overviewTopClasses = leaderboard.map((item) => {
                return {
                    ...item,
                    state: getStateShorthand(item.state),
                    totalScore: formatScore(item.totalScore),
                };
            });
        },
        setOverviewRisingClasses: (state, leaderboard) => {
            state.overviewRisingClasses = leaderboard.map((item) => {
                return {
                    ...item,
                    state: getStateShorthand(item.state),
                };
            });
        },
        setOverviewTopStateSchools: (state, leaderboard) => {
            state.overviewTopStateSchools = leaderboard.map((item) => {
                return {
                    ...item,
                    state: getStateShorthand(item.state),
                    totalScore: formatScore(item.totalScore),
                };
            });
        },
        setInfiniteLeaderboard: (state, leaderboard) => {
            state.infiniteLeaderboard = leaderboard.map((item, index) => {
                return {
                    ...item,
                    key: item.position || 'empty' + index,
                    schoolScore: formatScore(item.schoolScore),
                    homeScore: formatScore(item.homeScore),
                    totalScore: formatScore(item.totalScore),
                    seasonScore: formatScore(item.seasonScore),
                };
            });
        },
        setOwnRecords: (state, leaderboard) => {
            state.ownRecords = leaderboard.map((item, index) => {
                return {
                    ...item,
                    key: item.position || 'empty' + index,
                    schoolScore: formatScore(item.schoolScore),
                    homeScore: formatScore(item.homeScore),
                    totalScore: formatScore(item.totalScore),
                    seasonScore: formatScore(item.seasonScore),
                };
            });
        },
        setSelectedCompetition: (state, payload) => {
            state.selectedCompetition = payload;
        },
        setCompetitionClassCode: (state, classCode) => {
            console.debug(
                'setCompetitionClassCode mutation',
                state.teams,
                classCode,
            );
            state.competitionClassCode = classCode;
            state.competitionTeam = state.teams.find(
                (team) => team.classCode === classCode,
            );
            if (classCode) {
                state.customGrade = null;
            }
        },
        setCustomGrade: (state, grade) => {
            state.customGrade = grade;
        },
        setLeaderboardTopStudents: (state, data) => {
            state.leaderboardTopStudents = data;
        },
        setLeaderboardMostImproved: (state, data) => {
            state.leaderboardMostImproved = data;
        },
        setLeaderboardTopHomePlayers: (state, data) => {
            state.leaderboardTopHomePlayers = data;
        },
        setLeaderboardUpdateTime: (state, payload) => {
            state.leaderboardUpdateTime = payload;
        },
        setTeamsIsLoaded: (state, data) => {
            state.teamsIsLoaded = data;
        },
    },
    actions: {
        fetchCompetitionsConfig: async ({ commit }) => {
            try {
                const CACHE_KEY = 'competitionsConfig';
                const CACHE_TIMESTAMP_KEY = 'competitionsConfigTimestamp';
                const lastFetch = localStorage.getItem(CACHE_TIMESTAMP_KEY);
                // Check if last fetch was today
                if (
                    lastFetch &&
                    moment().isSame(moment(parseInt(lastFetch, 10)), 'day')
                ) {
                    const cachedData = localStorage.getItem(CACHE_KEY);
                    if (cachedData) {
                        commit('setCompetitionsConfig', JSON.parse(cachedData));
                        return;
                    }
                }
                const result = await SecureApi().get(`competitions/config/all`);
                if (!result.data.success) {
                    reportError('Error fetching competitions config', result);
                } else {
                    const data = result.data.data;
                    commit('setCompetitionsConfig', data);

                    // Cache the data
                    localStorage.setItem(CACHE_KEY, JSON.stringify(data));
                    localStorage.setItem(
                        CACHE_TIMESTAMP_KEY,
                        moment().valueOf().toString(),
                    );
                }
            } catch (error) {
                reportError('Error fetching competitions config', error);
            }
        },
        /**
         * @deprecated ? todo: check performance and need on FE and BE
         */
        fetchAllCompetitionEntries: async ({ commit }) => {
            try {
                const result = await SecureApi().get(
                    `competitions/registered-entries`,
                );
                if (!result.data.success) {
                    reportError(
                        'Error reading all competition entries',
                        result,
                    );
                }

                return result.data.data;
            } catch (error) {
                reportError('Error reading all competition entries', error);
            }
        },

        fetchTeams: async ({ commit }, payload) => {
            const competitionId = payload?.competitionId || null;
            let queryString = '?';
            if (competitionId) {
                queryString += `competitionId=${competitionId}`;
            }
            try {
                const result = await SecureApi().get(
                    `competitions${queryString}`,
                );
                if (!result.data.success) {
                    reportError(
                        'Error reading competition teams',
                        result,
                        () => {
                            commit('setTeams', []);
                        },
                    );
                }

                commit('setTeams', result.data.data);
                commit('setTeamsIsLoaded', true);
            } catch (error) {
                reportError('Error reading competition teams', error, () => {
                    commit('setTeams', []);
                    commit('setTeamsIsLoaded', true);
                });
            }
        },

        fetchOverviewRisingClasses: async ({ state, commit }) => {
            const competitionId = state.selectedCompetition?.id;
            try {
                const result = await SecureApi().get(
                    'competitions/leaderboard',
                    {
                        params: {
                            leaderboardId: `classRaise_${competitionId}`,
                            pageSize: 5,
                        },
                    },
                );
                if (result.data.success) {
                    commit('setOverviewRisingClasses', result.data.data);
                } else {
                    reportError(
                        'Error reading competition overview rising classes',
                        result,
                        () => {
                            commit('setOverviewRisingClasses', []);
                        },
                    );
                }
            } catch (error) {
                reportError(
                    'Error reading competition overview rising classes',
                    error,
                    () => {
                        commit('setOverviewRisingClasses', []);
                    },
                );
            }
        },
        fetchOverviewTopClasses: async ({ state, commit }, grade) => {
            const competitionId = state.selectedCompetition?.id;
            try {
                const result = await SecureApi().get(
                    'competitions/leaderboard',
                    {
                        params: {
                            leaderboardId: `${competitionId}`,
                            pageSize: 5,
                            grade,
                        },
                    },
                );
                if (result.data.success) {
                    commit('setOverviewTopClasses', result.data.data);
                } else {
                    reportError(
                        'Error reading competition overview top classes',
                        result,
                        () => {
                            commit('setOverviewTopClasses', []);
                        },
                    );
                }
            } catch (error) {
                reportError(
                    'Error reading competition overview top classes',
                    error,
                    () => {
                        commit('setOverviewTopClasses', []);
                    },
                );
            }
        },
        fetchOverviewMyClasses: async ({ state, commit }) => {
            const competitionId = state.selectedCompetition?.id;
            try {
                const result = await SecureApi().get('competitions', {
                    params: { competitionId },
                });
                if (result.data.success) {
                    commit('setOverviewMyClasses', result.data.data);
                } else {
                    reportError(
                        'Error reading competition overview my classes',
                        result,
                        () => {
                            commit('setOverviewMyClasses', []);
                        },
                    );
                }
            } catch (error) {
                reportError(
                    'Error reading competition overview my classes',
                    error,
                    () => {
                        commit('setOverviewMyClasses', []);
                    },
                );
            }
        },
        fetchOverviewTopStateSchools: async ({
            state,
            commit,
            rootGetters,
        }) => {
            const competitionId = state.selectedCompetition?.id;
            const participantState =
                state.teams?.[0]?.state || rootGetters.user?.country;

            try {
                const result = await SecureApi().get(
                    'competitions/leaderboard',
                    {
                        params: {
                            leaderboardId: `schools_${competitionId}`,
                            pageSize: 5,
                            state: participantState,
                        },
                    },
                );
                if (result.data.success) {
                    commit('setOverviewTopStateSchools', result.data.data);
                } else {
                    reportError(
                        'Error reading competition overview top schools',
                        result,
                        () => {
                            commit('setOverviewTopStateSchools', []);
                        },
                    );
                }
            } catch (error) {
                reportError(
                    'Error reading competition overview top schools',
                    error,
                    () => {
                        commit('setOverviewTopStateSchools', []);
                    },
                );
            }
        },
        /**Usage:
         *
           this.$store.dispatch(
            'v2/competition/fetchCurrentCompetitionLeaderboard',
            {
                competitionId: 'math-madness-25',
                grade: '1',
            },
        );
         */
        fetchCurrentCompetitionLeaderboard: ({ state, dispatch }, payload) => {
            const competitionId =
                payload.competitionId || state.selectedCompetition?.id;

            if (!competitionId) {
                console.error(
                    'fetchCurrentCompetitionLeaderboard:: Missing competitionId',
                );
                return;
            }

            const params = {
                ...payload,
                leaderboardId: competitionId,
            };

            return dispatch('fetchLeaderboard', params);
        },
        /**Usage:
         *
           this.$store.dispatch(
            'v2/competition/fetchMyClassLeaderboard',
            {
                competitionId: 'math-madness-25',
                participantId: 11810809,
                grade: '1',
            },
        );
         */
        fetchMyClassLeaderboard: ({ state, dispatch }, payload) => {
            const competitionId =
                payload.competitionId || state.selectedCompetition?.id;

            if (!competitionId) {
                console.error(
                    'fetchCurrentCompetitionLeaderboard:: Missing competitionId',
                );
                return;
            }

            const params = {
                ...payload,
                leaderboardId: competitionId,
            };

            return dispatch('fetchLeaderboard', params);
        },
        /**Usage:
         *
            this.$store.dispatch('v2/competition/fetchSchoolLeaderboard', {
                competitionId: 'math-madness-25',
            });
         */
        fetchSchoolLeaderboard: ({ state, dispatch }, payload) => {
            const competitionId =
                payload.competitionId || state.selectedCompetition?.id;

            if (!competitionId) {
                console.error('fetchSchoolLeaderboard:: Missing competitionId');
                return;
            }

            const params = {
                ...payload,
                leaderboardId: `schools_${competitionId}`,
            };

            return dispatch('fetchLeaderboard', params);
        },
        fetchMastersLeagueLeaderboard: async (
            { state, dispatch, commit, getters },
            payload,
        ) => {
            let { seasonId } = payload;
            seasonId = 'masters-spring-25';
            if (!seasonId) {
                console.error(
                    'fetchMastersLeagueLeaderboard:: Missing seasonId',
                );
                return;
            }
            if (!payload.grade) {
                console.warn('fetchMastersLeagueLeaderboard:: Missing grade');
                return;
            }
            const params = {
                ...payload,
                leaderboardId: seasonId,
            };

            const result = await dispatch('fetchLeaderboard', params);
            if (payload.own) {
                commit(
                    'setSeasonTotalParticipants',
                    result[0]?.totalParticipants || 0,
                );
                if (payload.participantId) {
                    if (
                        result.length > 0 &&
                        result[0].classCode === payload.participantId
                    ) {
                        return await dispatch('calculateMySeasonLeague');
                    } else {
                        commit('setOwnRecords', []);
                        commit('setCustomGrade', getters.getCompetitionGrade);
                    }
                }
            }
        },

        fetchLeaderboard: async ({ state, getters, commit }, payload) => {
            try {
                const result = await SecureApi().get(
                    'competitions/leaderboard',
                    {
                        params: {
                            ..._.pick(payload, [
                                'leaderboardId',
                                'participantId',
                                'grade',
                                'school',
                                'state',
                                'lastPosition',
                                'moveUp',
                            ]),
                            pageSize: payload.pageSize ?? 10,
                        },
                    },
                );
                if (result.data.success) {
                    commit('setLeaderboard', result.data.data);
                    const newChunk = result.data.data;
                    let board;

                    if (!payload.lastPosition || payload.reset) {
                        commit(
                            'setLeaderboardUpdateTime',
                            newChunk[0]?.lastComputedAt,
                        );
                        const datasetSize =
                            payload.forcedTotal ??
                            newChunk[0]?.filteredParticipants ??
                            newChunk[0]?.totalParticipants;
                        // size restriction is for the case like top 500
                        let total = payload.restrictSize
                            ? Math.min(payload.pageSize, datasetSize)
                            : datasetSize;
                        // avoid mismatch output
                        if (
                            datasetSize > newChunk.length &&
                            payload.pageSize > newChunk.length
                        ) {
                            total = newChunk.length;
                        }
                        board = Array(total).fill({});
                        newChunk.forEach((item, index) => {
                            board[index] = item;
                        });
                    } else {
                        board = [...state.infiniteLeaderboard];
                        const indexOfLastStored = board.findIndex(
                            (item) => item.position === payload.lastPosition,
                        );
                        if (indexOfLastStored === -1) {
                            console.warn(
                                'fetchLeaderboard: lastPosition not found; no place to add new chunk',
                            );
                        } else {
                            // append chunk
                            newChunk.forEach((item, index) => {
                                const updatePos = indexOfLastStored + 1 + index;
                                if (updatePos >= board.length) {
                                    console.warn(
                                        'new chunk overflows the leaderboard; skipping',
                                    );
                                    return;
                                }
                                board[updatePos] = item;
                            });
                            // crop array if chunk is not full
                            if (
                                newChunk.length &&
                                newChunk.length < payload.pageSize
                            ) {
                                board.length =
                                    indexOfLastStored + 1 + newChunk.length;
                                console.warn(
                                    'chunk is not full; cropping array to',
                                    board.length,
                                );
                            }
                        }
                    }
                    if (payload.own) {
                        commit('setOwnRecords', result.data.data);
                    } else {
                        commit('setInfiniteLeaderboard', board);
                    }
                    return result.data.data;
                } else {
                    reportError(
                        'Error reading competition leaderboard',
                        result,
                        () => {
                            commit('setLeaderboard', []);
                        },
                    );
                }
            } catch (error) {
                reportError(
                    'Error reading competition leaderboard',
                    error,
                    () => {
                        commit('setLeaderboard', []);
                    },
                );
            }
        },

        calculateMySeasonLeague: async ({ state, commit, dispatch }) => {
            const ownPosition = state.ownRecords[0]?.position;
            const myLeague = await dispatch(
                'calculateSeasonLeague',
                ownPosition,
            );
            if (ownPosition && myLeague) {
                commit('setSelectedYearlyLeague', myLeague);
            } else {
                commit(
                    'setSelectedYearlyLeague',
                    LEAGUES_CONFIG[LEAGUES_CONFIG.length - 1],
                );
                console.warn('No league found for the given position');
            }
        },
        calculateSeasonLeague: ({ state }, position) => {
            const totalParticipants = state.seasonTotalParticipants;
            if (
                !position ||
                !totalParticipants ||
                position > totalParticipants
            ) {
                console.error('Invalid position or total participants');
                return;
            }

            const percentile = (1 - (position - 1) / totalParticipants) * 100;

            const league = LEAGUES_CONFIG.find(
                (l) => percentile >= l.from && percentile <= l.to,
            );
            return league;
        },

        registerTeam: async ({ state, commit }, { classCode, formData }) => {
            let result, errorMessage;
            try {
                result = await SecureApi().post('competitions/register', {
                    classCode: classCode,
                    grade: formData.grade,
                    state: formData.state,
                    school: capitalize(formData.schoolName),
                    publicName: formData.publicName,
                });
            } catch (error) {
                errorMessage = error?.message;
                return {
                    success: false,
                    errorMessage,
                };
            }

            const { success, error, data: response } = result.data;
            if (success) {
                new TrackingService().track(
                    EVENTS.MONTHLY_COMPETITION_REGISTERS_CLASS,
                    {
                        classCode: classCode,
                        grade: formData.grade,
                        state: formData.state,
                        schoolName: capitalize(formData.schoolName),
                        publicClassName: formData.publicName,
                    },
                );
                commit('addOrUpdateTeam', response);
            } else {
                errorMessage = error?.message?.message;
            }

            return {
                success,
                errorMessage,
            };
        },
        editTeam: async ({ state, commit }, { classCode, formData }) => {
            let result, errorMessage;
            try {
                result = await SecureApi().patch('competitions/edit', {
                    classCode: classCode,
                    school: capitalize(formData.schoolName),
                    publicName: formData.publicName,
                });
            } catch (error) {
                errorMessage = error?.message;
                return {
                    success: false,
                    errorMessage,
                };
            }

            const { success, error, data: response } = result.data;
            if (success) {
                new TrackingService().track(
                    EVENTS.MONTHLY_COMPETITION_EDITS_CLASS,
                    {
                        classCode: classCode,
                        grade: formData.grade,
                        state: formData.state,
                        schoolName: capitalize(formData.schoolName),
                        publicClassName: formData.publicName,
                    },
                );
                commit('addOrUpdateTeam', response);
            } else {
                errorMessage = error?.message?.message;
            }

            return {
                success,
                errorMessage,
            };
        },

        fetchLeaderboardTopStudents: async ({ state, commit }) => {
            const competitionId = state.selectedCompetition?.id;

            try {
                const result = await SecureApi().get(
                    'competitions/leaderboard',
                    {
                        params: {
                            leaderboardId: `studentsTop_${competitionId}`,
                            pageSize: 6,
                        },
                    },
                );

                if (result.data.success) {
                    commit('setLeaderboardTopStudents', result.data.data);
                } else {
                    reportError(
                        'Error reading competition leaderboard top students',
                        result,
                        () => {
                            commit('setLeaderboardTopStudents', []);
                        },
                    );
                }
            } catch (error) {
                reportError(
                    'Error reading competition leaderboard top students',
                    error,
                    () => {
                        commit('setLeaderboardTopStudents', []);
                    },
                );
            }
        },
        fetchLeaderboardMostImproved: async ({ state, commit }) => {
            const competitionId = state.selectedCompetition?.id;

            try {
                const result = await SecureApi().get(
                    'competitions/leaderboard',
                    {
                        params: {
                            leaderboardId: `studentsRaise_${competitionId}`,
                            pageSize: 6,
                        },
                    },
                );

                if (result.data.success) {
                    commit('setLeaderboardMostImproved', result.data.data);
                } else {
                    reportError(
                        'Error reading competition leaderboard most improved students',
                        result,
                        () => {
                            commit('setLeaderboardMostImproved', []);
                        },
                    );
                }
            } catch (error) {
                reportError(
                    'Error reading competition leaderboard most improved students',
                    error,
                    () => {
                        commit('setLeaderboardMostImproved', []);
                    },
                );
            }
        },
        fetchLeaderboardTopHomePlayers: async ({ state, commit }) => {
            const competitionId = state.selectedCompetition?.id;

            try {
                const result = await SecureApi().get(
                    'competitions/leaderboard',
                    {
                        params: {
                            leaderboardId: `studentsHome_${competitionId}`,
                            pageSize: 6,
                        },
                    },
                );

                if (result.data.success) {
                    commit('setLeaderboardTopHomePlayers', result.data.data);
                } else {
                    reportError(
                        'Error reading competition leaderboard most improved students',
                        result,
                        () => {
                            commit('setLeaderboardTopHomePlayers', []);
                        },
                    );
                }
            } catch (error) {
                reportError(
                    'Error reading competition leaderboard most improved students',
                    error,
                    () => {
                        commit('setLeaderboardTopHomePlayers', []);
                    },
                );
            }
        },
    },
};
