import CrashReportsApi from '@/core/services/AdminApi.js';
import Api from '@/core/services/Api.js';
import { Base64 } from 'js-base64';
import SecureApi from '@/flows/Authentication/services/SecureApi.js';
import {
    requestGoogleAuthCode,
    requestGoogleToken,
} from '@/core/mixins/GoogleOauthUsageHelper.js';
import { inCapacitorBuildEnv, inProductionEnv } from '@/core/helpers/utils.js';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService.js';
import {
    KLAVIYO_EVENTS,
    KlaviyoService,
} from '@/core/services/KlaviyoService/KlaviyoService';
import {
    clearUpFirstName,
    fetchAndUpdateUser,
    getRandomPlayerName,
    getUserFromJwtToken,
    isParent,
    isStudent,
    isTeacher,
    SIMPLE_EMAIL_REGEX,
} from '@/flows/Authentication/helpers/AuthHelpers.js';
import moment from 'moment';
import { app } from '@/main.js';
import * as Sentry from '@sentry/vue';
import { pick } from 'lodash';
import { Gtag } from '@/core/services/GoogleAnalytics.js';
import { Hotjar } from '@/core/services/Hotjar.js';
import {
    ANONYMOUSEVENTS,
    AnonymousTrackingService,
} from '@/core/services/TrackingService/AnonymousTrackingService.js';
import { tests } from '@/core/mixins/ABTesting.js';
import { router } from '@/main.js';
import { DebugReportService } from '@/core/services/DebugReportService.js';
import { App } from '@capacitor/app';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { jwtDecode } from 'jwt-decode';
import { GenericOAuth2 } from '@capacitor-community/generic-oauth2';
// import { DebugReportService } from '@/core/services/DebugReportService.js';
import { i18n } from '@/lang/translator';
import { FLAGS } from '@/core/flags.js';

export default {
    namespaced: true,
    state: {
        user: null,
        submitButtonLoading: false,
        submitStatus: null,
        validationError: null,
        authProviderError: {},
        loginError: null,
    },
    getters: {
        user({ user }) {
            return user;
        },
        getUserId({ user }) {
            return user ? user.userId : null;
        },
        playSound({ user }) {
            return user ? user.playSound : null;
        },
        getNextPath({ nextPath }) {
            return nextPath;
        },
        getSignInfo({ user }) {
            return (user || {})._signInfo;
        },
        isUserFromUSA({ user }) {
            const isLocalUserFromUSA = !!localStorage
                .getItem('country_code3')
                ?.includes('USA');

            const _user = user || {};

            return (
                (_user.country && _user.country.includes('USA')) ||
                isLocalUserFromUSA
            );
        },
        getUsersPriceTest({ user }) {
            return user?.priceTestVersion || null;
        },
        cleverAuthAvailable() {
            return !inCapacitorBuildEnv();
        },
        inIosEnv() {
            return window.Capacitor?.getPlatform() === 'ios';
        },
        hasSchoolPremiumOrTrial(state, getters, rootState, rootGetters) {
            const user = getters.user;

            if (user?.role === 'student') {
                return (
                    user?.premium?.status === 'active' ||
                    user?.premium?.status === 'trial'
                );
            }

            return (
                user?.premium?.status === 'active' ||
                rootGetters['v2/teacherPremium/hasSchoolPremiumTrial']
            );
        },
        submitButtonLoading(state) {
            return state.submitButtonLoading;
        },
        submitStatus(state) {
            return state.submitStatus;
        },
        validationError(state) {
            return state.validationError;
        },
        loginError(state) {
            return state.loginError;
        },
    },
    mutations: {
        setUserData(state, data) {
            state.user = data;

            Sentry.setUser(
                pick(data, [
                    'userId',
                    'role',
                    'country',
                    'flags',
                    'username',
                    'studentInfo',
                ]),
            );
        },
        setPriceTestVersion(state, data = []) {
            state.user.priceTestVersion = data || [];
        },
        logOut(state) {
            state.user = null;

            Sentry.setUser(null);
        },
        setNextPath(state, nextPath) {
            state.nextPath = nextPath;
        },
        setUserXp(state, xp) {
            state.user.xp = xp;
        },
        setUserTier(state, tier) {
            state.user.tier = tier;
        },
        setUserTierInfo(state, tierInfo) {
            state.user.tierInfo = tierInfo;
        },
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        setSignInfo(state, signInfo) {
            state.user._signInfo = signInfo;
        },
        setAbTest(state, abTest) {
            state.user.abTests = { ...state.user.abTests, ...abTest };
        },
        setPublicAbTest(state, abTest) {
            state.publicAbTest = abTest;
        },
        setSubmitButtonLoading(state, next) {
            state.submitButtonLoading = next;
        },
        setSubmitStatus(state, next) {
            state.submitStatus = next;
        },
        setValidationError(state, next) {
            state.validationError = next;
        },
        setAuthProviderError(state, next) {
            state.authProviderError = next;
        },
        setLoginError(state, next) {
            state.loginError = next;
        },
    },
    actions: {
        async showWelcomeWizard(store) {
            if (isStudent(store.getters.user)) {
                if (
                    store.getters.hasSchoolPremiumOrTrial &&
                    !store.getters.soloTrackData
                ) {
                    await store.dispatch('v2/soloTrack/fetchSoloTrackData');
                }

                return (
                    store.getters.hasSchoolPremiumOrTrial &&
                    !store.getters.user?.flags?.isProgramPageViewed
                );
            } else {
                return false;
            }
        },
        sendMixpanelEvent(store, params) {
            const { signupOrLogin, emailOrGoogle, user, publicAbTest } = params;

            const referral = store.rootGetters.getReferral;

            const commonData = {
                ...user,
                loginType: emailOrGoogle,
                role: user.roles[0],
                referredBy:
                    referral ||
                    user.referredBy ||
                    sessionStorage.getItem('referredBy'),
                lastReferredBy: sessionStorage.getItem('lastReferredBy'),
                publicAbTest,
            };

            const cleanedUpUser = { ...user, role: user.roles[0] };

            switch (signupOrLogin) {
                case 'signup':
                    new TrackingService(cleanedUpUser).track(
                        EVENTS.SIGN_UP,
                        commonData,
                    );
                    break;
                case 'login':
                    new TrackingService(cleanedUpUser).track(
                        EVENTS.LOG_IN,
                        commonData,
                    );
                    break;
                default:
                    break;
            }
        },
        async initTest(store, testId) {
            const user = store.getters.user;
            const test = tests[testId];

            if (!test) console.debug('Test not found');

            if (user) {
                // do not init test for users with disabled tracking
                const optedOut = await window.mixpanel.has_opted_out_tracking();

                if (optedOut) {
                    return false;
                }

                const shouldIncludeUser = test?.filter(user) || false;
                const hasTestAlready =
                    user && user.abTests && !!user.abTests[testId];

                console.debug(
                    `[AbTest] Test: ${testId} Filter: ${shouldIncludeUser} HasTestAlready: ${hasTestAlready}`,
                );

                if (!shouldIncludeUser || hasTestAlready) {
                    return false;
                }

                const response = await SecureApi().post(
                    `ab-tests/subscribe/${testId}`,
                );

                const { success, data } = response.data;

                if (success && data) {
                    new TrackingService(user).track(EVENTS.ADD_AB_TEST, {
                        testId,
                        testValue: data.version,
                    });
                }

                await fetchAndUpdateUser(user);
            } else {
                console.debug('User not found');
            }
        },
        async postRegisterAbTests(store) {
            await store.dispatch('initTest', 'subscribe');
            await store.dispatch('initTest', 'hasMainViewStats');
        },
        async redirectUserAfterSignUp(store, payload) {
            const user = store.getters.user;
            const thisIsTeacher = isTeacher(user);

            const isLocalUserFromUSA = !!localStorage
                .getItem('country_code3')
                ?.includes('USA');

            store.commit('setSubmitButtonLoading', false);

            const shouldSeeTeacherOnboardingSlides =
                thisIsTeacher &&
                user.referredBy === 'clever-library' &&
                (user.country?.includes('USA') || isLocalUserFromUSA);

            if (shouldSeeTeacherOnboardingSlides) {
                return router.push({
                    name: 'clever-library-register',
                });
            } else {
                const someWhereToBe = store.getters.getNextPath;

                if (
                    someWhereToBe &&
                    someWhereToBe.path &&
                    someWhereToBe.path !== '/'
                ) {
                    store.commit('setNextPath', null);

                    return router.push(someWhereToBe);
                }

                if (payload.cancelNavigation) {
                    return;
                }

                if (isStudent(user)) {
                    if (payload.provider === 'email' || !payload.provider) {
                        return router.push({
                            name: 'home-game.v10.welcome-preloader',
                        });
                    }

                    return router.push({
                        name: 'home-game.v10.welcome-grades',
                    });
                } else if (isParent(user)) {
                    if (localStorage.getItem('parentSignupFacebook')) {
                        const url = new URL(
                            'https://parentonboarding.99math.com/99math-after-signup',
                        );

                        const query = router.currentRoute.query;

                        for (const q of Object.keys(query)) {
                            url.searchParams.append(q, query[q]);
                        }

                        return (window.location = url.toString());
                    }

                    return router.push({
                        name: 'home-game.v10.main-page',
                    });
                } else {
                    return router.push({ name: 'host' });
                }
            }
        },
        async postRegistrationSteps(store, payload) {
            const isLocalUserFromUSA = !!localStorage
                .getItem('country_code3')
                ?.includes('USA');

            const referral = store.rootGetters.getReferral;
            const user = getUserFromJwtToken(payload.userDataToken);

            if (!user || user.isGuest) {
                return;
            }

            if (isTeacher(user)) {
                sessionStorage.setItem('newTeacher', 'true');

                Gtag.teacherSignUp();

                if (payload.provider === 'clever') {
                    Hotjar.triggerCleverTeacherSignup();
                }

                if (user.country?.includes('USA') || isLocalUserFromUSA) {
                    Hotjar.triggerUSTeacherSignup();
                }

                Hotjar.triggerTeacherSignup();

                await store.dispatch('sendPixelEvent', 'Teacher signup');
            } else if (isStudent(user)) {
                Hotjar.triggerStudentSignup();
                Hotjar.tag(['StudentSignup']);
                await store.dispatch('flipFlag', FLAGS.BATTLE_PASS_V_21_SYNCED);

                new TrackingService(user).track(
                    EVENTS.SET_SKILLS_LEVEL_LOCK,
                    false,
                );
            } else if (
                isParent(user) &&
                localStorage.getItem('parentSignupFacebook')
            ) {
                await store.dispatch(
                    'sendPixelEvent',
                    'Parent facebook signup',
                );
            }

            if (referral) {
                Gtag.referredSignupEvent({ referral });
            }
            // caution: this will override test in parameter
            const publicAb = payload.publicAbTest
                ? {
                      testId: payload.publicAbTest.testId,
                      assignedOption: payload.publicAbTest.assignedOption,
                  }
                : payload.publicAbTest;

            store.dispatch('sendMixpanelEvent', {
                signupOrLogin: 'signup',
                emailOrGoogle: payload.provider,
                user,
                publicAb,
            });

            localStorage.setItem('authToken', payload.authToken);
            localStorage.setItem('userDataToken', payload.userDataToken);

            if (!isTeacher(user)) {
                sessionStorage.setItem(
                    'studentFirstTimeSession',
                    new Date().toISOString(),
                );

                sessionStorage.setItem('studentFirstTimeToPickAvatar', true);
            }

            await store.dispatch('v2/init', true);
            sessionStorage.removeItem('referral');
            store.commit('setReferral', null);
            await store.dispatch('setUserInfoToStore', user);
            sessionStorage.setItem('playerName', user.username);

            console.debug(`User ${user.userId} postRegistrationSteps`);

            store.dispatch('setSignInfoForStudentsWithoutAge', {
                loginType: payload.provider,
                referredBy: referral,
            });
            await store.dispatch('sendUserIdToMixpanel');
            await store.dispatch('addReferredByUser');
            await store.dispatch('postRegisterAbTests');
            console.log('pets debug');
            console.log(user.country);
            console.log(moment().hours());
            if (
                isStudent(user) &&
                user.country?.includes('USA') &&
                moment().hours() >= 16
            ) {
                console.log('setting pets game flag');
                await store.dispatch('flipFlag', FLAGS.PETS_GAME);
            }

            if (
                isParent(user) &&
                localStorage.getItem('parentSignupFacebook')
            ) {
                await store.dispatch('flipFlag', 'parentSignupFacebook');

                try {
                    await SecureApi().post('/referrals/tag-email', {
                        email: user.email,
                        tag: 'parent_signup_facebook_campaign',
                    });
                } catch (e) {
                    console.error(e);
                }
            }

            await store.dispatch('fetchUsersPriceTest');

            if (
                isTeacher(user) &&
                (user.country?.includes('USA') || isLocalUserFromUSA)
            ) {
                // If USA teacher then set alias for pre-register mixpanel events
                new AnonymousTrackingService().track(
                    ANONYMOUSEVENTS.TEACHER_ONBOARDING_SIGNUP_SET_ALIAS,
                    user.email,
                );

                if (window?.ttq?.track) {
                    window.ttq.track('AddToWishlist');
                }

                if (window?.twq) {
                    window.twq('event', 'tw-ocxs1-od0kq', {});
                }
            }

            if (import.meta.env.VITE_ENVIRONMENT === 'experimental') {
                const classCode = 96271977;

                await SecureApi()
                    .post(`class-lists/${classCode}/student/set-class-code`)
                    .catch(console.warn);

                await fetchAndUpdateUser(user).catch(console.warn);
            }
            // make sure to set user to store before this
            if (!payload.cancelNavigation) {
                store.dispatch('redirectUserAfterSignUp', {
                    cancelNavigation: payload.cancelNavigation,
                    provider: payload.provider,
                });
            }
        },
        async postLoginAbTests(store) {
            const referral = store.rootGetters.getReferral;
            const user = store.getters.user;

            const referredBy =
                referral ||
                user.referredBy ||
                sessionStorage.getItem('referredBy');

            if (
                user.role === 'student' &&
                referredBy === 'teacherCreatedAccount' &&
                !user.flags?.hasSentSignUpEvent
            ) {
                return await store.dispatch('postRegisterAbTests');
            }
        },
        async postLoginSteps(store, payload) {
            const user = getUserFromJwtToken(payload.userDataToken);

            if (!user || user.isGuest) {
                return false;
            }

            const thisIsStudent = isStudent(user);

            if (thisIsStudent) {
                Hotjar.triggerStudentLogin();
            }

            localStorage.setItem('userDataToken', payload.userDataToken);

            localStorage.setItem('authToken', payload.authToken);

            app.config.globalProperties.$debugReportService =
                new DebugReportService(this.$socket, user?.flags?.debugUser);

            store.commit('setLoginError', null);

            await store.dispatch('setUserInfoToStore', user);

            sessionStorage.setItem('playerName', user.username);

            await store.dispatch('v2/user/logIn');

            await store.dispatch('initTest', 'subscribe');

            await store.dispatch('v2/competition/fetchCompetitionsConfig');

            if (thisIsStudent) {
                store.dispatch('getUserClasses', { update: true });
                store.dispatch('v2/homegame/checkGradeRestore');
            }

            await store.dispatch('postLoginAbTests');

            store.dispatch('sendMixpanelEvent', {
                signupOrLogin: 'login',
                emailOrGoogle: payload.provider,
                user,
            });

            store.dispatch('setSignInfoForStudentsWithoutAge', {
                loginType: payload.provider,
                referredBy: this.getReferral,
            });

            await store.dispatch('fetchUsersPriceTest');

            const someWhereToBe = store.getters.getNextPath;

            store.commit('setSubmitButtonLoading', false);

            if (
                someWhereToBe &&
                someWhereToBe.path &&
                someWhereToBe.path !== '/'
            ) {
                store.commit('setNextPath', null);

                if (
                    someWhereToBe.path.includes('program') &&
                    (await store.dispatch('showWelcomeWizard'))
                ) {
                    return router.push({
                        name: 'home-game.v10.welcome-loader',
                    });
                } else if (someWhereToBe.path.includes('premiumTrialWelcome')) {
                    return router.push({
                        name: 'host',
                        query: {
                            premiumTrialWelcome: true,
                        },
                    });
                } else if (someWhereToBe.path.includes('premiumTrialEnd')) {
                    return router.push({
                        name: 'host',
                        query: {
                            premiumTrialEnd: true,
                        },
                    });
                } else if (someWhereToBe.path.includes('premiumDiscover')) {
                    return router.push({
                        name: 'host',
                        query: {
                            premiumDiscover: true,
                        },
                    });
                } else if (someWhereToBe.path.includes('premiumTrialModal')) {
                    return router.push({
                        name: 'host',
                        query: {
                            premiumTrialModal: true,
                        },
                    });
                } else {
                    return router.push(someWhereToBe);
                }
            }

            if (payload.cancelNavigation) {
                return;
            }

            if (thisIsStudent) {
                const nextRoute = (await store.dispatch('showWelcomeWizard'))
                    ? 'home-game.v10.welcome-loader'
                    : 'home-game.v10.main-page';

                return router.push({
                    name: nextRoute,
                });
            } else if (isParent(user)) {
                return router.push({ name: 'home-game.v10.main-page' });
            } else {
                if (router.currentRoute.query?.premiumTrialWelcome) {
                    return router.push({
                        name: 'host',
                        query: {
                            premiumTrialWelcome: true,
                        },
                    });
                }

                if (router.currentRoute.query?.premiumTrialEnd) {
                    return router.push({
                        name: 'host',
                        query: {
                            premiumTrialEnd: true,
                        },
                    });
                }

                return router.push({
                    name: 'host',
                    query: router.currentRoute.query,
                });
            }
        },
        async registerUserViaEmail(store, data) {
            data.$v.$touch();

            if (data.$v.$invalid) {
                store.commit('setSubmitStatus', 'ERROR');

                return;
            }

            store.commit('setSubmitButtonLoading', true);

            store.commit('setSubmitStatus', 'PENDING');

            const referral = store.rootGetters.getReferral;

            const playerName = getRandomPlayerName(
                clearUpFirstName({ firstName: data.firstName }),
            );

            sessionStorage.setItem('playerName', playerName);

            let filteredGrades = [];

            for (let i in data.grades) {
                if (data.grades[i] === true) {
                    filteredGrades.push(i);
                }
            }

            await store.commit('setTeachingGrades', filteredGrades);

            let identificationString = {};

            const isEmail = SIMPLE_EMAIL_REGEX.test(data.email);

            if (isEmail) {
                identificationString = { email: data.email };
            } else {
                identificationString = { username: data.email };
            }

            await store.dispatch('generateGuestJwt', null, {
                root: true,
            });

            const response = await store.dispatch('registerUserViaProvider', {
                ...identificationString,
                provider: 'email',
                title: data.role !== 'student' ? data.title : null,
                firstName: data.firstName || 'math',
                lastName: data.lastName || 'math',
                password: data.password,
                role: data.role,
                country: data.country || null,
                referredBy: referral,
                referringUserId: store.getters.getReferringUserId,
                teachingGrades: filteredGrades,
                playerName: playerName,
                publicAbTest: data.shortFormTest
                    ? {
                          testId: data.shortFormTest.testId,
                          assignedOption: data.shortFormTest.assignedOption,
                      }
                    : undefined,
            });

            if (response && response.success) {
                const {
                    authToken,
                    userDataToken,
                    isEmailExists = false,
                } = response.data;

                const publicAbTest = data.shortFormTest
                    ? {
                          registrationSlideTest_2:
                              data.shortFormTest.assignedOption,
                      }
                    : undefined;

                if (isEmailExists) {
                    // user should be logged in
                    store.dispatch(
                        'postLoginSteps',
                        { authToken, userDataToken },
                        'email',
                    );

                    sessionStorage.removeItem('teacherOnboardingSurvey');

                    return;
                }

                store.dispatch('postRegistrationSteps', {
                    authToken,
                    userDataToken,
                    provider: 'email',
                    cancelNavigation: data.cancelNavigation,
                    publicAbTest,
                });

                return 'ok';
            } else {
                store.commit('setSubmitButtonLoading', false);

                store.commit('setSubmitStatus', 'ERROR');

                if (response && response.data === 'EmailInUseException') {
                    store.commit(
                        'setValidationError',
                        i18n.tc('auth.registration.emailInUse'),
                    );
                } else if (
                    response &&
                    response.data === 'UsernameInUseException'
                ) {
                    store.commit(
                        'setValidationError',
                        i18n.tc('auth.registration.usernameInUse'),
                    );
                } else if (response && response.data === 'InvalidSymbols') {
                    store.commit(
                        'setValidationError',
                        i18n.tc('auth.registration.invalidSymbols'),
                    );
                } else {
                    store.commit(
                        'setValidationError',
                        i18n.tc('auth.registration.unableToCreateAccount'),
                    );
                }

                return store.state.validationError;
            }
        },
        async registerStudentByUsername(store, data) {
            // Not sure why this.$v is undefined here, but accessing it directly
            // from Component's data variables works.
            data.$v.$touch();

            if (data.$v.$invalid) {
                store.commit('setSubmitStatus', 'ERROR');

                return;
            }

            store.commit('setSubmitButtonLoading', true);

            store.commit('setSubmitStatus', 'PENDING');

            const referral = store.getters.getReferral;

            const username = 'true';

            sessionStorage.setItem('password', data?.password);

            const identificationString = { username };

            await store.dispatch('generateGuestJwt', null, {
                root: true,
            });

            const response = await store.dispatch('registerUserViaProvider', {
                ...identificationString,
                provider: 'email',
                firstName: data?.firstName || 'math',
                lastName: data?.lastName || 'math',
                password: data?.password,
                role: 'student',
                referredBy: referral,
                playerName: username,
                country: data?.country || null,
            });

            if (response && response.success) {
                const {
                    authToken,
                    userDataToken,
                    isUserExists = false,
                } = response.data;

                if (isUserExists) {
                    // user should be logged in
                    store.dispatch(
                        'postLoginSteps',
                        { authToken, userDataToken },
                        'email',
                    );

                    return;
                }

                store.dispatch(
                    'postRegistrationSteps',
                    { authToken, userDataToken },
                    'email',
                );
            } else {
                store.commit('setSubmitButtonLoading', false);

                store.commit('setSubmitStatus', 'ERROR');

                if (response && response.data === 'UsernameInUseException') {
                    store.commit(
                        'setValidationError',
                        i18n.tc('auth.registration.usernameInUse'),
                    );
                } else if (response && response.data === 'InvalidSymbols') {
                    store.commit(
                        'setValidationError',
                        i18n.tc('auth.registration.invalidSymbols'),
                    );
                } else {
                    store.commit(
                        'setValidationError',
                        i18n.tc('auth.registration.unableToCreateAccount'),
                    );
                }
            }
        },
        setSubmitButtonLoading(store, data) {
            store.commit('setSubmitButtonLoading', data);
        },
        setValidationError(store, data) {
            store.commit('setValidationError', data);
        },
        setSubmitStatus(store, data) {
            store.commit('setSubmitStatus', data);
        },
        setLoginError(store, data) {
            store.commit('setLoginError', data);
        },
        async loginUserViaProvider(store, data) {
            try {
                void CrashReportsApi()
                    .post(
                        `crash-reports/increase-daily-counter/login/${data.provider}Calls`,
                    )
                    .catch(console.warn);

                const response = await Api().post(
                    `/auth/login/${data.provider}`,
                    {
                        ...data,
                    },
                );

                console.log('=== loginUserViaProvider response', response);

                return response.data;
            } catch (error) {
                console.log('=== loginUserViaProvider error', error);

                this.googleSubmitLoading = false;

                console.error('lU error: ' + error);
            }
        },
        async retrieveCleverAuthToken(_, code) {
            const { origin } = window.location;

            const fullPath = `${origin}?fromClever=true`;

            const encodedRedirectURI = Base64.encodeURI(fullPath);

            const response = await SecureApi().get(
                `/auth/clever-token/${code}/${encodedRedirectURI}`,
            );

            const { data } = response;

            return data.success ? { ...data.data } : {};
        },
        async getCleverProfileFromAPI(_, token) {
            const response = await SecureApi().post(
                '/auth/clever/full-profile',
                {
                    token,
                },
            );

            const { data } = response;

            return data.success ? { ...data.data } : {};
        },
        async retrieveFullCleverProfileInfo({ dispatch }, code) {
            const cleverResponse = await dispatch(
                'retrieveCleverAuthToken',
                code,
            );
            if (cleverResponse.data) {
                const token = cleverResponse.data.access_token;

                const profileResponse = await dispatch(
                    'getCleverProfileFromAPI',
                    token,
                );

                if (profileResponse.data) {
                    const data = profileResponse.data.data;

                    console.log('clever profile info', profileResponse);

                    return {
                        role: Object.keys(data.roles)[0],
                        email: data.email,
                        id: data.id,
                        name: {
                            firstName: data.name.first,
                            lastName: data.name.last,
                        },
                    };
                }
            }

            return {};
        },
        async retrieveGoogleUser(_, payload = {}) {
            return Api()
                .post(`/auth/google/user-info`, payload)
                .then((response) => {
                    const responseData = response.data;
                    if (responseData.success) {
                        return {
                            success: true,
                            data: responseData.data,
                        };
                    } else {
                        return {
                            success: false,
                            data: responseData.error.message,
                        };
                    }
                })
                .catch((error) => {
                    this.googleSubmitLoading = false;

                    console.error('retrieveGoogleUser error: ' + error);
                });
        },
        async googleAuthWithToken({ dispatch }, options = {}) {
            const { access_token: token } = await requestGoogleToken(
                options,
            ).catch((error) => {
                this.googleSubmitLoading = false;
                console.error('requestGoogleToken error: ' + error);
            });

            const googleUser = await dispatch('retrieveGoogleUser', { token });

            return { ...googleUser.data, token };
        },
        async googleAuth(
            { dispatch },
            { redirectUrl, state = 'googleRedirect' },
        ) {
            const platform = window.Capacitor?.getPlatform();

            const handleAuth = {
                ['android']: () => dispatch('authWithGoogleInAndroid'),
                ['ios']: () => dispatch('googleAuthWithToken', {}),
                default: () => requestGoogleAuthCode({ redirectUrl, state }),
            };

            return (handleAuth[platform] || handleAuth.default)();
        },
        registerUserViaProvider({ state, getters }, data) {
            const savedFlags = JSON.parse(localStorage.getItem('99mathFlags'));

            const body = {
                ...data,
                locale: getters.getCurrentLanguage,
                device:
                    window.innerWidth < 1000
                        ? 'signup_mobile'
                        : 'signup_desktop',
                flags: savedFlags ? savedFlags : null,
            };

            // caution: this will override test in `data`
            if (state.publicAbTest) {
                body.publicAbTest = {
                    testId: state.publicAbTest.testId,
                    assignedOption: state.publicAbTest.assignedOption,
                };
            }

            if (inProductionEnv()) {
                try {
                    void CrashReportsApi().post(
                        `crash-reports/increase-daily-counter/register/${data.provider}Calls`,
                    );
                } catch (e) {
                    console.error(`CRapi: ${e}`);
                }
            }

            return Api()
                .post(`/auth/register/${data.provider}`, body)
                .then((response) => {
                    const responseData = response.data;
                    if (responseData.success) {
                        if (savedFlags) {
                            localStorage.removeItem('99mathFlags');
                        }

                        return { success: true, data: responseData.data };
                    } else {
                        return {
                            success: false,
                            data: responseData.error.message,
                        };
                    }
                })
                .catch((error) => {
                    this.googleSubmitLoading = false;

                    console.error('rU ' + data.provider + ' error: ' + error);
                });
        },
        async userExistsAlready(store, { email, googleUserId }) {
            const path = googleUserId
                ? `/auth/check/googleUserId?userId=${googleUserId}&email=${email}`
                : `/auth/check/email?email=${email}`;

            return await Api()
                .get(path)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.error('cE error: ', error);
                    // Returning true errors out this check, as it would mean
                    // that the email is taken.
                    return true;
                });
        },
        async sendChangePasswordEmail(_store, email) {
            const body = { email };

            const response = await Api().post('/auth/forgot-password', body);

            return response.data;
        },
        async changePassword(_store, data) {
            const response = await Api().post('/auth/change-password', data);

            return response && response.data;
        },
        setUserInfoToStore({ commit, getters, dispatch }, data) {
            commit('setUserData', {
                ...data,
                role: data.roles[0],
                playerName:
                    data.studentInfo?.playerName ||
                    data.gameInfo?.playerName ||
                    data.playerName,
                achievements: data.achievements || [],
                liveGamesPlayed: data.liveGamesPlayed || 0,
                selfPacedGamesPlayed: data.selfPacedGamesPlayed || 0,
                liveGamesMin4PlayersPlayed:
                    data.liveGamesMin4PlayersPlayed || 0,
                selfPacedGamesMin4PlayersPlayed:
                    data.selfPacedGamesMin4PlayersPlayed || 0,
                gameDaysCounter: data.gameDaysCounter || 0,
                _signInfo: data._signInfo || getters.getSignInfo,
            });

            if (getters.getCurrentLanguage !== data.locale) {
                dispatch('setLanguage', data.locale);
            }
        },
        removeUserData({ commit, getters, dispatch }) {
            localStorage.removeItem('userDataToken');
            localStorage.removeItem('authToken');

            const user = getters.user;

            if (user && !user.isGuest) {
                // We should only remove it for clients who were logged in.
                // If we remove it from people who aren't logged in,
                // they cant refresh during the game.
                sessionStorage.removeItem('playerName');

                if (
                    getters.getCurrentLanguage !==
                    getters.getClientBrowserLanguage
                ) {
                    dispatch('setLanguage', getters.getClientBrowserLanguage);
                }

                new TrackingService(user).track(EVENTS.LOG_OUT);

                const SessionStorageKeysWhiteList = ['playerName'];

                Object.keys(sessionStorage)
                    .filter(
                        (x) => SessionStorageKeysWhiteList.indexOf(x) === -1,
                    )
                    .forEach((x) => sessionStorage.removeItem(x));
            }

            localStorage.removeItem('teacherSurveyCompleted');
            localStorage.removeItem('referralCount');
            localStorage.removeItem('IDLE_TIMEOUT');
            localStorage.removeItem('IDLE_PRE_TIMEOUT');
            localStorage.removeItem('student-current-topic');
            localStorage.removeItem('solo-track-last-ended-round');
            localStorage.removeItem('playSound');
            localStorage.removeItem('iw-pathAssigned');

            dispatch('v2/soloTrack/resetState', true);

            dispatch('v2/homegame/resetStateToDefault');

            commit('v2/teacher/reports', []);

            /**
             * Remove IG data
             */
            Object.entries(localStorage)
                .map((x) => x[0])
                .filter((x) => x.substring(0, 2) === 'IG')
                .forEach((x) => localStorage.removeItem(x));

            commit('logOut');

            dispatch('v2/user/logOut');
        },
        async sendUserIdToMixpanel({ getters }) {
            const user = getters.user;

            const userId = getters.getUserId;

            new TrackingService(user).track(EVENTS.SET_USER_ID, userId);
        },
        async changeSoundVolume({ getters }, soundVolume) {
            const user = { ...getters.user };

            return SecureApi()
                .post(`/auth/set/sound/${soundVolume}`)
                .then((response) => {
                    const responseData = response.data;

                    if (responseData.success) {
                        fetchAndUpdateUser(user);
                    } else {
                        return {
                            success: false,
                            data: responseData.error.message,
                        };
                    }
                })
                .catch((error) => {
                    console.error('cSO error: ', error);
                });
        },
        patchParentVerification(_store, data) {
            return SecureApi()
                .patch(`/user/verification/parent`, data)
                .then(async (response) => {
                    const resp = response.data;

                    const user = resp.data;

                    if (resp.success) {
                        return { success: true, user };
                    } else {
                        return { success: false, data: resp.error.message };
                    }
                })
                .catch((error) => {
                    console.error('cSO error: ', error);
                });
        },
        changeProfilePassword(_store, payload) {
            return SecureApi()
                .post(`/user/change-password`, payload)
                .then((response) => {
                    const resp = response.data;

                    const user = resp.data;

                    if (resp.success) {
                        return { success: true, user };
                    } else {
                        return { success: false, data: resp.error.message };
                    }
                })
                .catch((error) => {
                    console.error('cSO error: ', error);
                });
        },
        async fetchUsersPriceTest({ commit, getters }, locationOpened) {
            if (getters.user?.priceTestVersion) {
                return;
            }

            const urlString = `/price-tests/fetch/${locationOpened || ''}`;

            const response = await SecureApi().get(urlString);

            const { success, data } = response.data;

            if (success) {
                commit('setPriceTestVersion', data);

                new TrackingService().track(EVENTS.SET_USER_PRICE_TEST, {
                    priceTestVersion: data.name,
                });
            }
        },
        async updateLastActivity({ commit, getters, dispatch }) {
            const previousLastActiveTime = getters.getLastActive;

            const thisActiveTime = moment();

            if (!previousLastActiveTime) {
                commit('setLastActive', thisActiveTime);

                return;
            }
            if (thisActiveTime.diff(previousLastActiveTime, 'days') > 1) {
                if (getters.user) {
                    dispatch('checkJwt');
                }
            }

            commit('setLastActive', thisActiveTime);
        },
        async checkJwt({ dispatch, commit, getters }) {
            const token = localStorage.getItem('authToken');
            const user = token && getUserFromJwtToken(token);
            const cleanUpUser = async () => {
                console.log(
                    'Token expired, invalid or gone. Removing user data',
                    token,
                );

                commit('v2/user/userId', null, { root: true });
                await dispatch('removeUserData');

                localStorage.removeItem('userDataToken');
                localStorage.removeItem('authToken');

                return false;
            };

            if (!user) return cleanUpUser();

            const { exp } = user;
            const currentTime = moment();
            const timeDiff = moment.unix(exp).diff(currentTime, 'days');

            // token not expired
            if (timeDiff > 0) {
                if (user.isGuest)
                    commit('v2/user/guestId', user.guestId, { root: true });
                else commit('v2/user/userId', user.userId, { root: true });

                // token expire less than in 3 days
                if (timeDiff < 3) {
                    if (!user.isGuest) await fetchAndUpdateUser(user);

                    return true;
                }

                if (!getters.user) await fetchAndUpdateUser();

                return true;
            } else {
                return cleanUpUser();
            }
        },
        async checkGuestJwt({ commit }) {
            const token = localStorage.getItem('guestToken');
            if (token) {
                const user = getUserFromJwtToken(token);
                if (user) {
                    const { exp } = user;

                    const currentTime = moment();

                    const timeDiff = moment.unix(exp).diff(currentTime, 'days');

                    // token not expired
                    if (timeDiff > 0) {
                        commit('v2/user/guestId', user.guestId, { root: true });

                        // token expire less then in 3 days
                        return timeDiff >= 3;
                    } else {
                        // if token expired remove user
                        commit('v2/user/guestId', null, { root: true });

                        localStorage.removeItem('guestToken');

                        return false;
                    }
                } else {
                    // corrupted token
                    return false;
                }
            }

            // token expired invalid or gone
            return false;
        },
        async generateGuestJwt({ commit }) {
            return Api()
                .post('/auth/generate-guest-jwt')
                .then((response) => {
                    const responseData = response.data;

                    if (responseData.success) {
                        const guest = getUserFromJwtToken(responseData.data);

                        if (!guest) {
                            console.error('open guest session failed!!!');

                            commit('v2/user/guestId', null, { root: true });

                            return { success: false };
                        }

                        commit('v2/user/guestId', guest.guestId, {
                            root: true,
                        });

                        localStorage.setItem('guestToken', responseData.data);

                        app.config.globalProperties.$emitter.emit(
                            'guestJwtAcquired',
                        );
                    } else {
                        return {
                            success: false,
                            data: responseData.error.message,
                        };
                    }
                })
                .catch((error) => {
                    console.error('cFL error: ', error);
                });
        },
        setNextPath({ commit }, nextPath) {
            commit('setNextPath', nextPath);
        },
        async markReportAsViewed({ dispatch }, { gameCode, gameType }) {
            try {
                const response = await SecureApi().post(
                    `game-reports/viewReport/${gameType}/${gameCode}`,
                );

                const { error, success, data } = response.data;

                if (error) {
                    console.error('mRV server error: ' + error);
                }
                if (success) {
                    dispatch('getReports', -1);

                    return data;
                }
            } catch (error) {
                console.error('hCmRV request error: ' + error);
            }
        },
        async flipFlag({ getters, dispatch }, payload) {
            const flagId =
                typeof payload === 'object' ? payload.flagId : payload;

            const forceUpdate =
                typeof payload === 'object' ? payload.forceUpdate : false;

            const user = getters.user;

            if (user?.flags && user?.flags[flagId] && !forceUpdate) {
                return;
            }
            try {
                const response = await SecureApi().post(`user/flags/${flagId}`);

                const { error, success, data } = response.data;

                if (error) {
                    console.error('flipFlag request error: ' + error);
                }

                if (success) {
                    dispatch(
                        'v2/user/update',
                        { accountInfo: data },
                        { root: true },
                    );

                    return data;
                }
            } catch (error) {
                console.log('flipFlag request error: ' + error);
            }
        },
        setSignInfoForStudentsWithoutAge({ commit, getters }, data) {
            const user = getters.user || {};

            if (user.role === 'student' && !user.ageInfo) {
                commit('setSignInfo', data);
            }
        },
        async incrementGameDaysCounter({ getters, dispatch }) {
            try {
                const response = await SecureApi().post(
                    'user/update-game-days-counter',
                );

                const { success, data } = response.data;

                if (success) {
                    await fetchAndUpdateUser(getters.user);

                    const GDCounter = getters.user?.gameDaysCounter?.counter;

                    if (GDCounter === 2) {
                        await dispatch('sendPixelEvent', 'Reached GD2');
                    } else if (GDCounter === 4) {
                        await dispatch('sendPixelEvent', 'Reached GD4');
                    }

                    return { ...data };
                }

                return {};
            } catch (error) {
                console.log('Update counter days request error: ' + error);

                return {};
            }
        },
        async authWithApple(
            { commit, dispatch, getters },
            { firstName, lastName } = {},
        ) {
            commit('setSubmitButtonLoading', true);
            commit('setAuthProviderError', {});
            const currentRoute = router.currentRoute?.value?.name;

            try {
                const clientId = (await App.getInfo()).id;
                console.debug('=== clientId', clientId);

                const { response: appleResponse } =
                    await SignInWithApple.authorize({
                        clientId,
                        nonce: '' + +new Date(),
                        scopes: 'name email',
                        state: 'initial',
                    });

                // below flow is following the one from googleValidate
                if (appleResponse && appleResponse.identityToken) {
                    const { sub, email } = jwtDecode(
                        appleResponse.identityToken,
                    );

                    // Decode JWT to get user data (if necessary)
                    const userData = {
                        provider: 'apple',
                        role: 'student',
                        email,
                        appleUserId: sub,
                        locale: getters.getCurrentLanguage,
                        firstName: firstName || appleResponse.givenName,
                        lastName: lastName || appleResponse.familyName,
                        device: 'signup_mobile',
                        idToken: appleResponse.identityToken,
                    };

                    // Call backend API to register user
                    const response = await dispatch(
                        'registerUserViaProvider',
                        userData,
                    );
                    console.debug('=== registerResponse', response);

                    const { success, data } = response;

                    if (
                        data?.error &&
                        data?.message ===
                            'firstName and lastName must be defined for register'
                    ) {
                        commit('setAuthProviderError', {
                            provider: 'apple',
                            msg:
                                'Please enter First name and First' +
                                ' letter of last name to Sign up with Apple',
                        });

                        if (currentRoute !== 'auth.register')
                            await router.push({
                                name: 'auth.register',
                                query: { role: 'student' },
                            });

                        return;
                    } else if (!success)
                        throw new Error('Unsuccessful' + ' register');

                    const {
                        authToken,
                        userDataToken,
                        isUserExists = false,
                    } = data;

                    if (success && isUserExists) {
                        // user should be logged in
                        dispatch('postLoginSteps', {
                            authToken,
                            userDataToken,
                            provider: 'google',
                        });

                        sessionStorage.removeItem('teacherOnboardingSurvey');

                        return;
                    }

                    dispatch('postRegistrationSteps', {
                        authToken,
                        userDataToken,
                        provider: 'google',
                        cancelNavigation: false,
                        publicAbTest: this.shortFormTestData
                            ? {
                                  'publicAbTest.registrationSlideTest_2':
                                      this.shortFormTestData.assignedOption,
                              }
                            : undefined,
                    });
                } else {
                    console.error('=== apple response error', appleResponse);

                    throw new Error(
                        'Something goes wrong with apple authorize',
                    );
                }
            } catch (error) {
                console.error('=== error', error);

                if (
                    error?.message?.includes(
                        'The operation couldn’t be completed',
                    )
                )
                    return;

                alert(
                    `Unable to ${currentRoute === 'auth.login' ? 'login to' : 'create an'} account. ` +
                        'Please feel free to contact us to fix this!',
                );
            } finally {
                commit('setSubmitButtonLoading', false);
            }
        },
        async authWithGoogleInAndroid() {
            return GenericOAuth2.authenticate({
                authorizationBaseUrl:
                    'https://accounts.google.com/o/oauth2/auth',
                accessTokenEndpoint:
                    'https://www.googleapis.com/oauth2/v4/token',
                scope: 'email profile',
                resourceUrl: 'https://www.googleapis.com/userinfo/v2/me',
                pkceEnabled: true,
                android: {
                    appId: import.meta.env.VITE_ANDROID_GOOGLE_CLIENT_ID,
                    responseType: 'code',
                    redirectUrl: `${import.meta.env.VITE_MOBILE_APP_ID}:/`,
                },
            });
        },
    },
};
